import { Container } from '@material-ui/core'
import React from 'react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import styled from 'styled-components'
import MonumentCardAsset from '../components/MonumentCard/MonumentCardAsset'

const audiobooks = [
  {
    title: 'Przedsiębiorstwo Komunikacji Samochodowej w Mińsku Mazowieckim',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Tadeusz+Perczyn%CC%81ski+-+Przedsie%CC%A8biorstwo+Komunikacji+Samochodowej+w+Min%CC%81sku+Mazowieckim.mp3',
    author: 'Tadeusz Perczyński',
  },
  {
    title:
      'Tadeusz Doria-Dernałowicz (1916-1956) - ostatni dziecic Repek i Mińska Mazowiecki',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Damian+Sitkiewicz+-+Tadeusz+Doria-Derna%C5%82owicz+(1916-1956)+-+ostatni+dziecic+Repek+i+Min%CC%81ska+Mazowiecki.mp3',
    author: 'Damian Sitkiewicz',
  },
  {
    title: 'Historia przystanku PKP Mińsk Mazowiecki - Anielina',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Zbigniew+Grzesiak+-+Historia+przystanku+PKP+Min%CC%81sk+Mazowiecki+-+Anielina.mp3',
    author: 'Zbigniew Grzesiak',
  },
  {
    title:
      'Bohaterska postawa chłopa z Dębego Wielkiego Ludwika Walesiaka, podczas powstania styczniowego 1863 r. Przedruk Gość Świąteczny 1913',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Bohaterska+postawa+ch%C5%82opa+z+De%CC%A8bego+Wielkiego+Ludwika+Walesiaka%2C+podczas+powstania+styczniowego+1863+r.+Przedruk+Gos%CC%81c%CC%81+S%CC%81wia%CC%A8teczny+1913.mp3',
    author: '',
  },
  {
    title:
      'Dobre - dawne miasteczko w ziemi liwskiej, Rocznik Mińskomazowiecki tom 3, 1995-1996',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Dobre_dawne_miasteczko_w_ziemi_liwskiej_RMM_r1995_1996_t3_s211_221.mp3',
    author: 'Maria Danksza',
  },
  {
    title:
      'Dla pamięci. Moje wspomnienia ze wsi Nart, Rocznik Mińskomazowiecki tom 21, 2013 r.',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/gm+Jakub%C3%B3w+Eliasz+Roman+W%C4%85sak+Dla+pami%C4%99ci+moje+wspomnienia+ze+wsi+Nart.mp3',
    author: 'Eliasz Roman Wąsak',
  },
  {
    title: 'Mistów - Dzieje wsi i nazwy (zarys)',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Sylwester+Wocial+Misto%CC%81w+-+Dzieje+wsi+i+nazwy+(zarys).mp3',
    author: 'Sylwester Wocial',
  },
  {
    title: 'K. Sr. Nowy Kościół w Kałuszynie (1896r)',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/K.+Sr.+Nowy+Kos%CC%81cio%CC%81%C5%82+w+Ka%C5%82uszynie+(1896r).mp3',
    author: '',
  },
  {
    title:
      'Eugeniusz Religa (1909-1995) - międzywojenny nauczyciel i żołnierz, Rocznik Mińskomazowiecki tom 23, 2015 r.',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/gm+Latowicz+e_religa_latowicz.mp3',
    author: 'Zdzisław Ćmoch',
  },
  {
    title:
      'Sanatorium Rudka w latach 1908-1950 (zarys dziejów Sanatorium dla Piersiowo Chorych), Rocznik Mińskomazowiecki tom 11, 2003-2004 r.',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/gm+Mrozy+Sanatoriu+Rudka+S%C5%82awomir+Kuligowski.mp3',
    author: 'Sławomir Kuligowski',
  },
  {
    title: 'Siennica i seminarium  (1927)',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/P.+Szela%CC%A8g+Siennica+i+seminarium++(1927).mp3',
    author: 'P. Szeląg',
  },
  {
    title: 'Moja Szkoła w Sulejówku, Rocznik Mińskomazowiecki tom 25, 2017 r.',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/gm+Sulej%C3%B3wek+Ewa+Kara%C5%9B+Moja+Szko%C5%82a+w+Sulej%C3%B3wku.mp3',
    author: 'Ewa Karaś',
  },
  {
    title: 'Była Taka Fabryka - Historia Zakładów Rudzki i Spółka',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/By%C5%82a+Taka+Fabryka+-+Historia+Zak%C5%82ado%CC%81w+Rudzki+i+Spo%CC%81%C5%82ka%2C+Arkadiusz+%C5%81ukasiak+%3A+tom+17%2C+2009.mp3',
    author: 'Arkadiusz Łukasiak',
  },
  {
    title: 'Mariawityzm w Mińsku Mazowieckim',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Mariawityzm+w+Min%CC%81sku+Mazowieckim%2C+Krzysztof+Mazur%2C+ks.+Jan+Opala+%3A+Tom+27%2C+2019+r.mp3',
    authors: 'Krzysztof Mazur, ks. Jan Opala',
  },
  {
    title:
      'Przymusowa praca Żydów powiatu mińskiego - placówki i kolumny robocze przed Akcją Reinhardt (1939-1942)',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Przymusowa+praca+Z%CC%87ydo%CC%81w+powiatu+min%CC%81skiego+-+placo%CC%81wki+i+kolumny+robocze+przed+Akcja%CC%A8+Reinhardt+(1939-1942)+-+Ewa+Maria+Borkowska+%3A+tom+24%2C+2016+r.mp3',
    author: 'Ewa Maria Borkowska',
  },
  {
    title: 'Tajne nauczanie w Mińsku Mazowieckim i powiecie w latach 1939-1944',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Tajne+nauczanie+w+Min%CC%81sku+Mazowieckim+i+powiecie+w+latach+1939-1944+Tadeusz+i+W%C5%82adys%C5%82aw+Wo%CC%81jcik+%3A+Tom+2%2C+1994+r.mp3',
    authors: 'Tadeusz i Władysław Wójcik',
  },
  {
    title: 'Wydarzenia 1939-1944',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Wydarzenia+1939-1944+Edmund+Rajn%CC%81sz+%3A+Tom+5%2C+1999+r.mp3',
    author: 'Edmund Rajńsz',
  },
  {
    title: 'Właściciele Mińsk w świetle dokumentów',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/W%C5%82as%CC%81ciciele+Min%CC%81sk+w+s%CC%81wietle+dokumento%CC%81w%2C+W%C5%82adys%C5%82aw+Rudzin%CC%81ski%2C+tom+1%2C+1992.mp3',
    author: 'Władysław Rudziński',
  },
  {
    title:
      'Z dziejów konspiracyjnego harcerstwa kompania szarych szeregów obwodu armii krajowej mewa mińsk mazowiecki',
    link:
      'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Z+dziejo%CC%81w+konspiracyjnego+harcerstwa+kompania+szarych+szerego%CC%81w+obwodu+armii+krajowej+mewa+min%CC%81sk+mazowiecki%2C+Dr+hab.+Zbigniew+Gnat-Wieteska%2C+prof.+AON%2C+Tom+17%2C+2009+r.mp3',
    author: 'Dr hab. Zbigniew Gnat-Wieteska, prof. AON',
  },
]

const AudiobookiPage = ({ data }) => (
  <>
    <SEO title="Audiobooki" />
    <Container style={{ marginTop: 30, marginBottom: 50 }}>
      {audiobooks.map(el => (
        <MonumentCardAsset
          title={el.title}
          author={el.author || ''}
          authors={el.authors || ''}
          audioURL={el.link}
          assetName={'AUDIOBOOK'}
          audioIcon
        />
      ))}
    </Container>
  </>
)

// export const query = graphql`
//   {
//     # mapaniepodlegla: file(
//     #   relativePath: { regex: "/universal/" }
//     #   name: { regex: "/niepodleglamapa/" }
//     # ) {
//     #   childImageSharp {
//     #     fluid(maxWidth: 1200, quality: 100) {
//     #       ...GatsbyImageSharpFluid
//     #     }
//     #   }
//     # }
//     # logoniepodlegla: file(
//     #   relativePath: { regex: "/universal/" }
//     #   name: { regex: "/niepodlegla-logo/" }
//     # ) {
//     #   childImageSharp {
//     #     fluid(maxWidth: 1200, quality: 100) {
//     #       ...GatsbyImageSharpFluid
//     #     }
//     #   }
//     # }
//   }
// `

export default AudiobookiPage
